<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-left-panel :data-item='dataItem'></project-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Proje Dosyaları</h3>

					<p class='text-lg'>
					</p>

				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <ProjectTabMenu :project-id='projectId'></ProjectTabMenu>
                <div class='col-12'>


                    <div class='field grid mb-5'>
                        <label for='breakfastPrice' class='col-12 mb-2 md:col-2 md:mb-0'>Dosya Tipi</label>
                        <div class='col-12 md:col-6'>
                            <Dropdown id="fileType" v-model="selectedFileType" :options="fileTypes" optionLabel="name" option-value='name' placeholder="Seçim Yapın" @change='fileTypeChanged' ></Dropdown>
                        </div>

                    </div>

                    <div class='field grid mb-5'>
                        <label for='breakfastPrice' class='col-12 mb-2 md:col-2 md:mb-0'>Dosya Tipi</label>
                        <div class='col-12 md:col-6'>
                            <FileUpload choose-label="Upload" mode="basic" name="demo[]" :url="uploadUrl" :auto="true" @before-upload='uploadStarted' @upload="uploadFinished" />
                        </div>

                    </div>

                    <DataTable
                        :value='dataList'
                        :paginator='true'
                        class='p-datatable-gridlines'
                        :rows='5'
                        dataKey='id'
                        :rowHover='true'
                        filterDisplay='menu'
                        :loading='loadingIndicator'
                        responsiveLayout='scroll'
                    >


                        <template #empty>
                            Veri bulunamadı.
                        </template>

                        <template #loading>
                            Proje listesi yükleniyor. Lütfen bekleyin.
                        </template>

                        <Column field='insertDate' header='Tarih' filterField='insertDate'
                                :showFilterMatchModes='false'>
                            <template #body='{data}'>
                                {{ formatDate(data.insertDate) }}
                            </template>
                        </Column>
                        <Column field='fileType' header='Dosya Tipi' filterField='fileType'
                                :showFilterMatchModes='false'>
                        </Column>
                        <Column field='FilePath' header='Tarih' filterField='insertDate'
                                :showFilterMatchModes='false'>
                            <template #body='{data}'>
                                <a :href='"http://image.a1catering.com.tr" + data.filePath' target='_blank'> İndir </a>
                            </template>
                        </Column>
                        <Column field='managerUser.name' header='Yükleyen' filterField='fileType'
                                :showFilterMatchModes='false'>
                            <template #body='{data}'>
                                {{ data.managerUser.name + " " + data.managerUser.surName}}
                            </template>
                        </Column>
                        <Column header='Sil' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                            <template #body='slotProps'>
                                <Button icon='pi pi-trash' class='p-button-rounded p-button-primary'
                                        @click='deleteFile(slotProps.data)' />
                            </template>
                        </Column>
                    </DataTable>
                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage, showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import ProjectService from '../../services/projectService';
import AdminToast from '@/components/shared/toast/adminToast';
import ProjectLeftPanel from './projectLeftPanel';
import ProjectTabMenu from './projectTabMenu';
import moment from 'moment';
import store from '@/store';
import ProjectFileService from '../../services/projectFileService';
import { getProject } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';

export default {
    components: { ProjectTabMenu, ProjectLeftPanel, AdminToast },
    _projectService: null,
    _projectFileService : null,
    created() {
        this._projectFileService = new ProjectFileService();
        this._projectService = new ProjectService();
        this.projectId = this.$route.params.projectId;
        this.userId = store.getters.getUserId;
        this.uploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/file?fileType=" + this.selectedFileType + "&resourceId=" + this.projectId + "&resourceType=Project&managerUserId=" + this.userId;
    },
    async mounted() {
        checkActiveProjectIdIsValid(this, this.projectId);
        this.loadingIndicator = true;
        this.dataItem = await getProject(this.projectId);
        await this.getProjectFileList();

        this.loadingIndicator = false;

    },
    data() {
        return {
            userId : 0,
            uploadUrl:"",
            selectedFileType:"Sözleşme",
            fileTypes:[
                {
                    name: "Sözleşme",
                    id:1
                },
                {
                    name: "Teslim Belgesi",
                    id:2
                },
                {
                    name: "Demirbaş Listesi",
                    id:3
                },
                {
                    name: "Makbuz",
                    id:4
                },
                {
                    name: "Diğer",
                    id:5
                },
                {
                    name: "Logo",
                    id:6
                },
                {
                    name: "Baca Temizlik Formları",
                    id:7
                },
                {
                    name: "İlaçlama Tutanağı",
                    id:8
                },
                {
                    name: "Bakım - Onarım",
                    id:9
                },
                {
                    name: "İşletme Kayıt Belgesi",
                    id:10
                },
                {
                    name: "Arıtma Dezenfeksiyon Belgeleri",
                    id:11
                },
                {
                    name: "Numune Tutanağı",
                    id:12
                },
                {
                    name: "İSG Denetim Tutanağı",
                    id:13
                },
                {
                    name: "İşveren Vekil Ataması",
                    id:14
                },
                {
                    name: "İlçe Tarım Denetim",
                    id:15
                },
            ],
            dataList: [],
            projectId: 0,
            loadingIndicator: false,
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                startDate: '',
                endDate: '',
                personName: '',
                personPhone: '',
                personEmail: '',
                managerUserId: 0,
            },

            display: false,
            visibleLeft: false,
        };
    },
    methods: {


        async saveConfirm() {
            this.$confirm.require({
                message: 'Varsayılan fiyatların güncellenmesini istediğinizden eminmisiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    await this.save();
                },
            });
        },
        async getProjectFileList() {
            let fileResponse = await this._projectFileService.getAllProjectFiles(this.projectId, "Project");
            if (fileResponse.isSuccess) {
                this.dataList = fileResponse.data;
            }
        },
        async save() {

        },
        fileTypeChanged() {
            this.uploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/file?fileType=" + this.selectedFileType + "&resourceId=" + this.projectId + "&resourceType=Project&managerUserId=" + this.userId;
        },

        validateForm() {
            if (this.selectedFileType == null || this.selectedFileType === '') {
                showValidationMessage(this, 'Lütfen dosya tipini boş bırakmayın');
                return false;
            }

            return true;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
        async uploadFinished() {
            showSuccessMessage(this, "Dosya yükleme işlemi başarılı")
            await this.getProjectFileList()
            this.loadingIndicator = false;
        },
        uploadStarted() {
            this.loadingIndicator = true;
        },

        deleteFile(item) {
            this.$confirm.require({
                message: 'Dosyayı silmek istediğinize eminmisiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let deleteResponse = await this._projectFileService.deleteProjectFile(item.id);
                    if (deleteResponse.isSuccess) {
                        showSuccessMessage(this, 'Silme işlemi başarılı');
                        await this.getProjectFileList();
                    } else {
                        showErrorMessage(this, 'Silme işlemi sırasında hata oluştu');
                    }
                }
            });
        }
    },
};

</script>


<style scoped>

</style>
